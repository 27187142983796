import * as React from 'react';
import * as SecureStore from 'src/clients/SecureStore';
import { useRouter } from 'next/router';

export const useAuthStatus = () => {
  const router = useRouter();

  React.useEffect(() => {
    try {
      const secureValues = SecureStore.get([SecureStore.Key.BusinessManagerId, SecureStore.Key.BusinessId]);
      if (secureValues['x-sp-b-id'] == null || secureValues['x-sp-bm-id'] == null) {
        router.push(`/login?redirect=${encodeURIComponent(router.asPath)}`);
      }
    } catch (err: any) {
      // If failed, just proceed as if logged out
      router.push(`/login?redirect=${encodeURIComponent(router.asPath)}`);
    }
  }, []);
};
