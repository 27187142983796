import * as React from 'react';
import * as AppOpenAuthed from 'src/hooks/lib/AppOpenAuthed';
import * as Sentry from '@sentry/nextjs';
import * as State from 'src/state';

const SWITCH_URL =
  'https://metadata.app.oneshop.com/invsys-reseller/live/static/invsys-reseller/auth-institutions.json';

export const useInstitutions = () => {
  React.useEffect(() => {
    const listener = AppOpenAuthed.Value.addListener((ok) => {
      if (ok) {
        try {
          const p = fetch(SWITCH_URL, {
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: '0',
            },
          })
            .then((_) => _.json())
            .then((res) => {
              State.Observe.InstitutionLinks.MetadataAsyncValue.set(res);
            });
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    });
    listener(AppOpenAuthed.Value.get(), !AppOpenAuthed.Value.get());
    return () => {
      AppOpenAuthed.Value.removeListener(listener);
    };
  }, []);
};
