import * as React from 'react';
import * as Sentry from '@sentry/nextjs';
import * as State from 'src/state';
import { AppState } from 'react-native';

const SWITCH_URL =
  'https://metadata.app.oneshop.com/invsys-reseller/live/static/invsys-reseller/static-feature-switches.json';

export const useStaticSwitches = () => {
  React.useEffect(() => {
    const cb = async () => {
      if (AppState.currentState === 'active') {
        try {
          await fetch(SWITCH_URL, {
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: '0',
            },
          })
            .then((_) => _.json())
            .then((r) => {
              State.Observe.StaticFeatureSwitches.Value.set(r);
            });
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    };
    cb();
    const eventSubscriptionResponse = AppState.addEventListener('change', cb);
    return () => {
      eventSubscriptionResponse.remove();
    };
  }, []);
};
