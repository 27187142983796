import * as React from 'react';
import * as AppOpenAuthed from 'src/hooks/lib/AppOpenAuthed';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';

export const useSuppliesOrderPending = () => {
  React.useEffect(() => {
    let intervalId: undefined | ReturnType<typeof setInterval>;
    const _clearInterval = () => {
      if (intervalId != null) {
        clearInterval(intervalId);
      }
    };
    const listener = State.Observe.Supplies.SuppliesOrderPendingValue.addListener((isPending) => {
      if (isPending) {
        _clearInterval();
        intervalId = setInterval(async () => {
          if (AppOpenAuthed.Value.get()) {
            const res = await Network.gql.uspsFreeSupplyOrderingLedger({});
            const orderInProgress = res.invsysUspsFreeSupplyOrderingLedger.orderInProgress;
            if (!orderInProgress) {
              _clearInterval();
            }
          }
        }, 30000);
      } else {
        _clearInterval();
      }
    });
    return () => {
      _clearInterval();
      State.Observe.Supplies.SuppliesOrderPendingValue.removeListener(listener);
    };
  }, []);
};
