import * as React from 'react';
import * as Constants from 'src/constants';

const SWITCH_URL = 'https://metadata.app.oneshop.com/invsys-reseller/live/static/invsys-reseller/clients.json';

const RAND_INTERVAL = (1 + Math.random() * 2) * 1000 * 60;

export const useLatestVersion = () => {
  React.useEffect(() => {
    const interval = setInterval(async () => {
      const parsed = await fetch(SWITCH_URL, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      }).then((_) => _.json());

      const webMinVersion = parsed.web.minSaasVersion;
      if (webMinVersion && !isNaN(parseInt(webMinVersion)) && parseInt(webMinVersion) > Constants.Version) {
        document.location.reload();
      }
    }, RAND_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);
};
