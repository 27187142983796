import Cookie from 'js-cookie';
import * as Observe from 'src/state/lib/observe';
import { EnvironmentManifest } from 'src/constants/Environment';

export const KEYCHAIN_SERVICE_SUFFIX =
  EnvironmentManifest.API_ENV === 'production' ? '' : '-' + EnvironmentManifest.API_ENV;
const KEYCHAIN_SERVICE = 'oneshop' + KEYCHAIN_SERVICE_SUFFIX;

export enum Key {
  BusinessManagerId = 'x-sp-bm-id',
  BusinessId = 'x-sp-b-id',
  BusinessManagerToken = 'x-sp-bm-token',
  IdentitySessionToken = 'x-sp-id-session-token',
}

export type StoreType = {
  [key in Key]?: string;
};

export const StoreValue = Observe.Value<StoreType>({});

export const get = (keys: Key[]): Partial<StoreType> => {
  const res: StoreType = {};
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    res[key] = Cookie.get(key);
  }
  StoreValue.transform((current) => {
    const update = {
      ...current,
      ...res,
    };
    return update;
  });
  return res;
};

export const remove = (keys: Key[]): void => {
  keys.map((key) => Cookie.remove(key));
  StoreValue.transform((current) => {
    const update = {
      ...current,
    };
    keys.forEach((key) => {
      delete update[key];
    });
    return update;
  });
};

export const set = (partialStore: Partial<StoreType>): void => {
  for (const key in partialStore) {
    Cookie.set(key, partialStore[key as Key] ?? '');
  }
  StoreValue.transform((current) => {
    return {
      ...current,
      ...partialStore,
    };
  });
};
