import React from 'react';
import * as Network from 'src/clients/Network';
import * as Hooks from 'src/hooks';
import * as SecureStore from 'src/clients/SecureStore';
import * as AppOpenAuthed from 'src/hooks/lib/AppOpenAuthed';
import * as Sentry from '@sentry/nextjs';
import * as AmplitudeClient from 'src/clients/Amplitude';
import Router from 'next/router';

interface AuthHooksIface {
  useNuxRedirect?: boolean;
}
export const useFeaturesAndAuthHooks = (cfg?: AuthHooksIface) => {
  const [credentialsLoaded, setCredentialsLoaded] = React.useState<boolean>(false);
  Hooks.Auth.useAuthStatus();
  Hooks.Auth.useInstitutions();
  Hooks.Auth.useRequestLifecycle();
  Hooks.Listing.useListingsSummary();
  Hooks.Listing.useEnums();
  Hooks.Features.useStaticSwitches();
  Hooks.Version.useLatestVersion();
  Hooks.Supplies.useSuppliesMetadata();
  Hooks.Supplies.useSuppliesOrderPending();
  Hooks.ShippingLabels.useShippingLabelReel();

  const appReady: boolean = credentialsLoaded;

  React.useEffect(() => {
    const currPath = Router.asPath;
    if (currPath.includes('u/bots')) {
      Router.push(`/u/settings`);
    }
    const secureStoreCb = async (secureStore: SecureStore.StoreType) => {
      if (secureStore['x-sp-b-id'] != null && secureStore['x-sp-bm-id'] != null) {
        console.log('checkpoint 0');
        Sentry.setUser({
          id: secureStore['x-sp-bm-id'],
        });
        AmplitudeClient.setUserIdAsync(secureStore['x-sp-bm-id']);
        Network.gql.getAppCriticalMetadata({});
      }
    };
    SecureStore.StoreValue.addListener(secureStoreCb);

    const cb = async () => {
      try {
        await SecureStore.get([SecureStore.Key.BusinessManagerId, SecureStore.Key.BusinessId]);
        setCredentialsLoaded(true);
        secureStoreCb(SecureStore.StoreValue.get());
      } catch (e) {
        // If failed, just proceed as if logged out
        setCredentialsLoaded(true);
      }
    };
    cb();

    const listener = AppOpenAuthed.Value.addListener((ok) => {
      if (ok) {
        Network.gql.Listing.getAppCriticalMetadata({});
        Network.gql.getAppCriticalMetadata({});
        Network.gql.trackAppOpen({});
      }
    });
    listener(AppOpenAuthed.Value.get(), !AppOpenAuthed.Value.get());

    return () => {
      SecureStore.StoreValue.removeListener(secureStoreCb);
      AppOpenAuthed.Value.removeListener(listener);
    };
  }, []);

  //TODO: add auth guarding
  // if (!appReady) {
  //   return null;
  // }
};
