import * as React from 'react';
import * as State from 'src/state';
import * as Sentry from '@sentry/nextjs';
import { AppState } from 'react-native';

const ENUMS_URL = 'https://metadata.app.oneshop.com/invsys-reseller/live/static/invsys-reseller/listing-enums.json';

export const useEnums = () => {
  React.useEffect(() => {
    const cb = async () => {
      if (AppState.currentState === 'active') {
        try {
          const p = fetch(ENUMS_URL, {
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: '0',
            },
          }).then((_) => _.json());
          State.Observe.ListingForm.ListingEnumsMetadataAsyncValue.resolve(p);
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    };
    cb();
    const changeListener = AppState.addEventListener('change', cb);
    return () => {
      changeListener.remove();
    };
  }, []);
};
