import * as React from 'react';
import * as AppOpenAuthed from 'src/hooks/lib/AppOpenAuthed';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';

export const useListingsSummary = () => {
  React.useEffect(() => {
    let intervalId: undefined | ReturnType<typeof setInterval>;
    const _clearInterval = () => {
      if (intervalId != null) {
        clearInterval(intervalId);
      }
    };
    const listener = State.Observe.Listings.ListingsSummaryValue.addListener((summary) => {
      const nextPostAt = summary?.nextPostAt;
      const finished = nextPostAt == null;
      if (!finished) {
        _clearInterval();
        intervalId = setInterval(async () => {
          if (AppOpenAuthed.Value.get()) {
            const res = await Network.gql.listingsSummary({});
            const newNextPostAt = res.invsysListingsSummary?.nextPostAt;
            const newFinished = newNextPostAt == null;
            if (newFinished) {
              _clearInterval();
            }
          }
        }, 15000);
      } else {
        _clearInterval();
      }
    });
    return () => {
      _clearInterval();
      State.Observe.Listings.ListingsSummaryValue.removeListener(listener);
    };
  }, []);
};
