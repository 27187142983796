import * as SecureStore from 'src/clients/SecureStore';
import * as Observe from 'src/util/observe';
import { AppState } from 'react-native';

export const Value = Observe.Value<boolean>(false);

const cb = () => {
  const store = SecureStore.get([SecureStore.Key.BusinessManagerId, SecureStore.Key.BusinessId]);
  Value.set(AppState.currentState === 'active' && store['x-sp-b-id'] != null && store['x-sp-bm-id'] != null);
};

SecureStore.StoreValue.addListener(cb);
AppState.addEventListener('change', cb);
cb();
