import { AmplitudeClient } from 'amplitude-js';
import * as Constants from 'src/constants';

let Amplitude: AmplitudeClient | null = null;

let initialized = false;

const ApiKey = '998c25af56ecc4fb212c1f8072259989';

export const initialize = async (cb: (c: AmplitudeClient) => void) => {
  console.log('checkpoint 1');
  if (Constants.EnvironmentManifest.UI_ENV != 'production') {
    return;
  }

  const ssr = typeof window === 'undefined' || typeof document === 'undefined';
  if (!ssr) {
    console.log('checkpoint 2');
    if (Amplitude == null) {
      const AmplitudeSDK = require('amplitude-js').default;
      Amplitude = AmplitudeSDK.getInstance();
    }
    if (Amplitude != null) {
      console.log('checkpoint 3');
      if (!initialized) {
        console.log('checkpoint 4');
        Amplitude.init(ApiKey, undefined, { includeReferrer: true, includeUtm: true, includeGclid: true });
        initialized = true;
      }
      cb(Amplitude);
    }
  }
};

export const setUserIdAsync = async (userId: string): Promise<void> => {
  console.log('checkpoint 5');
  return new Promise((resolve, reject) => {
    initialize((cli) => {
      console.log('checkpoint 6');
      cli.setUserId(userId);
    });
    resolve();
  });
};

export const logEventAsync = async (eventName: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    initialize((cli) => {
      cli.logEvent(eventName);
    });
    resolve();
  });
};

export const logEventWithPropertiesAsync = async (eventName: string, properties: any): Promise<void> => {
  return new Promise((resolve, reject) => {
    initialize((cli) => {
      cli.logEvent(eventName, properties);
    });
    resolve();
  });
};
