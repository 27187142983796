import * as React from 'react';
import * as AppOpenAuthed from 'src/hooks/lib/AppOpenAuthed';
import * as Network from 'src/clients/Network';
import * as State from 'src/state';

export const useRequestLifecycle = () => {
  React.useEffect(() => {
    let intervalId: undefined | ReturnType<typeof setInterval>;
    const listener = State.Observe.InstitutionLinks.InstitutionLinkRequestsValue.addListener((requests) => {
      const allFinished = !requests.some((_) => _.status?.startsWith('PENDING'));
      if (!allFinished) {
        if (intervalId != null) {
          clearInterval(intervalId);
        }
        intervalId = setInterval(async () => {
          if (AppOpenAuthed.Value.get()) {
            const res = await Network.gql.getAppCriticalMetadata({});
            const newRequests = res.invsysInstitutionLinkRequests;
            const allFinished = !newRequests.some((_) => _.status?.startsWith('PENDING'));
            if (allFinished) {
              if (intervalId != null) {
                clearInterval(intervalId);
              }
            }
          }
        }, 2500);
      } else {
        if (intervalId != null) {
          clearInterval(intervalId);
        }
      }
    });
    return () => {
      if (intervalId != null) {
        clearInterval(intervalId);
      }
      State.Observe.InstitutionLinks.InstitutionLinkRequestsValue.removeListener(listener);
    };
  }, []);
};
