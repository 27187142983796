import * as React from 'react';
import * as State from 'src/state';
import * as Util from 'src/util';

export const usePaywallCanPass = (): boolean => {
  const [now, setNow] = React.useState<number>(Date.now());
  const business = Util.Observe.React.useValue(State.Observe.Business.Value);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(Date.now());
    }, 15 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (business == null) {
    return false;
  }

  const pastTrial = now > (business.trialEndAt ?? 0);
  const subscribed =
    business.subscriptionStatus?.status === State.Observe.Business.SubscriptionStatus.Active ||
    business.subscriptionStatus?.status === State.Observe.Business.SubscriptionStatus.Trialing;

  return !subscribed && pastTrial && !business.appReviewer;
};
